<template>
  <ModalWrapper @close="onClose">
    <div
      class="inline-block align-bottom bg-gray-0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full relative"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <button class="absolute right-5 top-5" @click="onClose">
        <img src="@/assets/icons/x.svg" alt="close" class="w-8 h-8" />
      </button>
      <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-col items-center ">
        <h2 class="text-48 mb-10 text-center">
          Sign Up
        </h2>
        <form class="w-full px-10 flex flex-col space-y-10">
          <div class="text-center">
            Username
            <t-input id="name" ref="name" v-model="name" />
            <p class="text-red-500">{{ nameError }}</p>
          </div>
          <div class="text-center">
            Email address
            <t-input id="email" v-model="email" />
            <p class="text-red-500">{{ emailError }}</p>
          </div>
          <div class="flex flex-col items-center space-y-4">
            <div class="max-w-max mx-auto">
              <t-button @click.prevent="signUp">
                Submit
              </t-button>
            </div>
            <div class="text-14">
              Already registered?
              <span
                class="font-bold text-blue-450 cursor-pointer"
                @click="showSignUpModal"
                >Sign in</span
              >
            </div>
          </div>
        </form>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import * as api from '@/api';

import ModalWrapper from '@/components/modals/ModalWrapper';
import eventBus from '@/utils/eventBus';

export default {
  name: 'SignUpModal',
  components: {
    ModalWrapper,
  },
  data() {
    return {
      name: '',
      nameError: '',
      email: '',
      emailError: '',
    };
  },
  mounted() {
    this.$refs.name.focus();
  },
  methods: {
    async signUp() {
      this.error = '';
      try {
        const { email } = await api.post('register', {
          name: this.name,
          email: this.email,
        });
        this.email = email;
        this.onClose();
        eventBus.$emit('open-approve-account');
      } catch (e) {
        if (e.response.data) {
          this.nameError = (e.response.data.name || []).join(' ');
          this.emailError = (e.response.data.email || []).join(' ');
        }
      }
    },
    showSignUpModal() {
      this.$emit('show-sign-in-modal');
      this.onClose();
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
