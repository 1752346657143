function getUser() {
  const storedUser = localStorage.getItem('user');
  if (storedUser) {
    return JSON.parse(storedUser);
  }
  return {
    email: '',
    name: '',
    is_staff: false,
    deposit_address: '',
    state: '',
    eth_support_enabled: false,
  };
}

export default function() {
  return getUser();
}
