export function SET_USER_DATA(state, userData) {
  state.email = userData.email;
  state.name = userData.name;
  state.is_staff = userData.is_staff;
  state.deposit_address = userData.deposit_address;
  state.state = userData.state;
  state.eth_support_enabled = userData.eth_support_enabled;
}

export function LOGOUT(state) {
  state.email = '';
  state.name = '';
  state.is_staff = false;
  state.deposit_address = '';
  state.state = '';
  state.eth_support_enabled = false;
}
