import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import './assets/tailwind.css';
import infiniteScroll from 'vue-infinite-scroll';
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

import VueTailwind from 'vue-tailwind';
import {
  TButton,
  TDropdown,
  TModal,
  TSelect,
  TInput,
  TTextarea,
} from 'vue-tailwind/dist/components';

const settings = {
  't-modal': {
    component: TModal,
    props: {
      fixedClasses: {
        overlay:
          'z-40  overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50',
        wrapper: 'relative mx-auto z-50 max-w-lg px-3 py-12',
        modal: 'overflow-visible relative  rounded',
        body: 'p-3',
        header: 'border-b p-3 rounded-t',
        footer: ' p-3 rounded-b',
        close:
          'flex items-center justify-center rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
      },
      classes: {
        overlay: 'bg-black',
        wrapper: '',
        modal: 'bg-white shadow',
        body: 'p-3',
        header: 'border-gray-100',
        footer: 'bg-gray-100',
        close: 'bg-gray-100 text-gray-600 hover:bg-gray-200',
        closeIcon: 'fill-current h-4 w-4',
        overlayEnterClass: 'opacity-0',
        overlayEnterActiveClass: 'transition ease-out duration-100',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'opacity-100',
        overlayLeaveActiveClass: 'transition ease-in duration-75',
        overlayLeaveToClass: 'opacity-0',
        enterClass: '',
        enterActiveClass: '',
        enterToClass: '',
        leaveClass: '',
        leaveActiveClass: '',
        leaveToClass: '',
      },
    },
  },
  't-nav-button': {
    component: TButton,
    props: {
      classes:
        'inline-flex whitespace-nowrap items-center h-10 px-5 text-18 text-gray-50 transition-colors duration-150 bg-blue-650 hover:bg-blue-700 focus:bg-blue-700 rounded-lg focus:shadow-sm z-30 outline-none focus:outline-none',
    },
  },
  't-light-button': {
    component: TButton,
    props: {
      classes:
        'block initial:w-full text-center bg-blue-50 hover:bg-blue-100 focus:bg-blue-100 focus:shadow-sm focus:outline-none text-blue-450 text-16 leading-6 font-medium py-3 px-6 rounded disabled:opacity-50 disabled:cursor-not-allowed',
    },
  },
  't-button': {
    component: TButton,
    props: {
      classes:
        'block initial:w-full text-center bg-blue-450 hover:bg-blue-600 focus:bg-blue-600 focus:shadow-sm focus:outline-none text-gray-50 text-16 leading-6 font-medium py-3 px-6 rounded disabled:opacity-50 disabled:cursor-not-allowed',
    },
  },
  't-danger-button': {
    component: TButton,
    props: {
      classes:
        'block initial:w-full text-center bg-red-500 hover:bg-red-600 focus:bg-red-600 focus:shadow-sm focus:outline-none text-gray-50 text-16 leading-6 font-medium py-3 px-6 rounded disabled:opacity-50 disabled:cursor-not-allowed',
    },
  },
  't-notification-button': {
    component: TButton,
    props: {
      classes:
        'block w-full text-center bg-blue-650 hover:bg-blue-700 focus:bg-blue-700 focus:shadow-sm focus:outline-none text-gray-50 text-16 leading-6 font-medium p-3 rounded-full disabled:opacity-50 disabled:cursor-not-allowed relative',
    },
  },
  'nav-dropdown': {
    component: TDropdown,
    props: {
      classes: {
        wrapper: 'inline-flex flex-col border-0 ring-0 outline-none',
        dropdownWrapper: 'relative border-0 ring-0 outline-none',
        dropdown:
          'origin-top-left absolute z-30 transform left-0 md:left-1/2 md:-translate-x-1/2 rounded shadow bg-white mt-1 border-0 ring-0 outline-none',
        enterClass: '',
        enterActiveClass:
          'transition ease-out duration-100 z-30 transform opacity-0 scale-95',
        enterToClass: 'transform opacity-100 z-30 scale-100',
        leaveClass: 'transition ease-in transform z-30 opacity-100 scale-100',
        leaveActiveClass: '',
        leaveToClass: 'transform opacity-0 scale-95 z-30 duration-75',
      },
    },
  },
  'notification-dropdown': {
    component: TDropdown,
    props: {
      classes: {
        wrapper: 'inline-flex flex-col border-0 ring-0 outline-none',
        dropdownWrapper: 'relative border-0 ring-0 outline-none',
        dropdown:
          'absolute z-40 transform -right-38 sm:-right-10 rounded shadow bg-white mt-1 border-0 ring-0 outline-none rounded-lg px-4 md:px-10 py-6',
        enterClass: '',
        enterActiveClass:
          'transition ease-out duration-100 z-30 transform opacity-0 scale-95',
        enterToClass: 'transform opacity-100 z-30 scale-100',
        leaveClass: 'transition ease-in transform z-30 opacity-100 scale-100',
        leaveActiveClass: '',
        leaveToClass: 'transform opacity-0 scale-95 z-30 duration-75',
      },
    },
  },

  'menu-nav-dropdown': {
    component: TDropdown,
    props: {
      classes: {
        wrapper: 'inline-flex flex-col border-0 ring-0 outline-none',
        dropdownWrapper: 'relative border-0 ring-0 outline-none',
        dropdown:
          'origin-top-left absolute w-full z-30 left-0 rounded shadow bg-white mt-1 border-0 ring-0 outline-none',
        enterClass: '',
        enterActiveClass:
          'transition ease-out duration-100 z-30 transform opacity-0 scale-95',
        enterToClass: 'transform opacity-100 z-30 scale-100',
        leaveClass: 'transition ease-in transform z-30 opacity-100 scale-100',
        leaveActiveClass: '',
        leaveToClass: 'transform opacity-0 scale-95 z-30 duration-75',
      },
    },
  },
  'cancel-modal-button': {
    component: TButton,
    props: {
      classes:
        'w-full justify-center rounded-md border-2  shadow-sm sm:w-36 py-2 sm:py-3 bg-gray-50 sm:text-16 text-14 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none hover:border-blue-600 focus:bor der-blue-700',
    },
  },
  't-filter-select': {
    component: TSelect,
    props: {
      classes:
        'block pl-3 pr-10 py-2 transition duration-100 ease-in-out border-0 ring-0 outline-none rounded focus:outline-none focus:ring-0 focus:border-0 disabled:opacity-50 disabled:cursor-not-allowed text-gray-800 placeholder-gray-400 bg-transparent cursor-pointer',
    },
  },
  't-select': {
    component: TSelect,
    props: {
      classes:
        'focus:border-blue-700 hover:border-blue-600 placeholder-gray-400 rounded-md px-4 border-2 outline-none py-1 w-full text-16',
    },
  },
  't-input': {
    component: TInput,
    props: {
      classes:
        'focus:border-blue-700 hover:border-blue-600 placeholder-gray-400 rounded-md px-4 border-2 outline-none py-1 w-full text-16',
    },
  },
  't-textarea': {
    component: TTextarea,
    props: {
      rows: '3',
      classes:
        'focus:border-blue-700 hover:border-blue-600 placeholder-gray-400 rounded-md px-4 border-2 outline-none py-1 w-full text-16',
    },
  },
  't-file-input': {
    component: TInput,
    props: {
      type: 'file',
      classes:
        'w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100',
    },
  },
};

Vue.component('v-select', vSelect);
Vue.use(VueTailwind, settings);
Vue.use(infiniteScroll);

new Vue({
  store,
  router,
  directives: { infiniteScroll },
  render: h => h(App),
}).$mount('#app');
