<template>
  <!-- eslint-disable vue/no-v-html -->
  <div :class="wrapperClass" role="alert">
    <span :class="badgeClass">{{ badgeLabel }}</span>
    <span class="font-semibold mr-2 text-left flex-auto" v-html="message" />
    <span class="box-6" @click="closeAlert">
      <img
        src="@/assets/icons/close_red.svg"
        alt="close alert"
        class="max-w-none box-6"
      />
    </span>
  </div>
</template>
<script>
import eventBus from '@/utils/eventBus';

const SECOND_BEFORE_AUTO_CLOSE = 15;

export default {
  name: 'Alert',
  props: {
    message: {
      type: String,
      required: true,
    },
    alertType: {
      type: String,
      default: 'success',
    },
  },
  data() {
    return {
      timer: '',
    };
  },

  computed: {
    wrapperClass() {
      return 'p-2 bg-blue-700 items-center text-gray-50 leading-none flex w-full absolute z-10';
    },
    badgeClass() {
      if (this.alertType === 'success') {
        return 'flex rounded-full bg-green-500 uppercase px-2 py-1 text-12 font-bold mr-3';
      } else {
        return 'flex rounded-full bg-red-500 uppercase px-2 py-1 text-12 font-bold mr-3';
      }
    },

    badgeLabel() {
      return this.alertType;
    },
  },
  mounted() {
    this.timer = setTimeout(() => {
      this.closeAlert();
    }, SECOND_BEFORE_AUTO_CLOSE * 1000);
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    closeAlert() {
      eventBus.$emit('close-alert');
    },
  },
};
</script>
<style scoped>
.box-6 {
  width: 24px;
  height: 24px;
}
</style>
