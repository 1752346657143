<template>
  <div>
    <div :class="buttonStyle" class="focus-within:border-gray-100">
      <t-nav-button
        v-if="mobile"
        class="min-width-83 items-center"
        classes="text-16 md:text-18 w-full text-left capitalize font-medium flex"
        @click="toggleMobileOptions"
      >
        {{ action }}
      </t-nav-button>
      <nav-dropdown v-else :class="{ 'w-full': mobile }">
        <div
          slot="trigger"
          slot-scope="{
            mousedownHandler,
            focusHandler,
            blurHandler,
            keydownHandler,
            isShown,
          }"
        >
          <t-nav-button
            classes="text-16 md:text-18 capitalize w-20 font-medium outline-none"
            :class="{ 'text-left w-full': mobile }"
            @mousedown="mousedownHandler"
            @focus="focusHandler"
            @blur="blurHandler"
            @keydown="keydownHandler"
            @click="focusButton(isShown)"
          >
            {{ action }}
          </t-nav-button>
        </div>
        <div slot-scope="{ hide, blurHandler }" class="z-10 relative">
          <button
            v-if="user.eth_support_enabled"
            ref="firstbutton"
            class="account-option static-width-80"
            role="menuitem"
            @blur="blurHandler"
            @click="openModal('ETH', hide)"
          >
            ETH
          </button>
          <hr v-if="user.eth_support_enabled" />
          <button
            ref="secondbutton"
            class="account-option static-width-80"
            role="menuitem"
            @blur="blurHandler"
            @click="openModal('USDT', hide)"
          >
            USDT
          </button>
          <hr v-if="action === 'withdraw'" />
          <button
            v-if="action === 'withdraw'"
            class="account-option static-width-80"
            role="menuitem"
            @blur="blurHandler"
            @click="openNFTWithdrawModal(hide)"
          >
            NFT
          </button>
        </div>
      </nav-dropdown>
    </div>
    <div
      v-if="isOpenMoblieOptions && mobile"
      class="ml-3 mt-1 mb-3 bg-transparent text-gray-50 flex flex-col space-y-1"
    >
      <button
        v-if="user.eth_support_enabled"
        class="text-left focus:bg-gray-50 hover:bg-gray-50 focus:text-blue-700 hover:text-blue-700 hover:bg-opacity-90 rounded-md py-0.5 px-3 max-w-min"
        role="menuitem"
        @click="openModal('ETH')"
      >
        ETH
      </button>
      <button
        class="text-left focus:bg-gray-50 hover:bg-gray-50 focus:text-blue-700 hover:text-blue-700 hover:bg-opacity-90 rounded-md py-0.5 px-3 max-w-min"
        role="menuitem"
        @click="openModal('USDT')"
      >
        USDT
      </button>
      <button
        v-if="action === 'withdraw'"
        class="text-left focus:bg-gray-50 hover:bg-gray-50 focus:text-blue-700 hover:text-blue-700 hover:bg-opacity-90 rounded-md py-0.5 px-3 max-w-min"
        role="menuitem"
        @click="openNFTWithdrawModal()"
      >
        NFT
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NavBarActionButton',
  props: {
    action: {
      type: String,
      required: true,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isOpenMoblieOptions: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
    buttonStyle() {
      let classes = '';
      if (this.mobile) {
        classes =
          'border-transparent hover:bg-blue-700 hover:border-gray-300 hover:text-gray-50 block pl-3 pr-4 py-2 border-l-4 text-16 font-medium';
      } else {
        classes =
          'text-gray-50 inline-flex items-center pt-1 font-medium h-full border-transparent border-b-2 hover:border-gray-100';
      }

      return classes;
    },
  },
  methods: {
    focusButton(isShown) {
      if (isShown) return;
      if (this.$refs.buttonfirst) {
        this.$refs.buttonfirst.focus();
      } else {
        this.$refs.secondbutton.focus();
      }
    },
    //start action method that open modal with event bus
    openModal(currency, hide = false) {
      this.$emit('open-modal', currency, this.mobile);
      if (hide) {
        hide();
      }
    },
    openNFTWithdrawModal(hide = false) {
      this.$emit('open-nft-withdraw-modal', this.mobile);
      if (hide) {
        hide();
      }
    },
    toggleMobileOptions() {
      this.isOpenMoblieOptions = !this.isOpenMoblieOptions;
    },
  },
};
</script>
<style scoped>
.logo {
  display: inline;
  height: 0.75rem;
}
.static-width-80 {
  width: 80px;
}
</style>
