<template>
  <ModalWrapper @close="onClose">
    <div
      class="inline-block align-bottom bg-gray-0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full relative"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <button class="absolute right-5 top-5" @click="onClose">
        <img src="@/assets/icons/x.svg" alt="close" class="w-8 h-8" />
      </button>
      <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="flex items-center text-24">
          {{ currency }}
          <img
            v-if="currency === 'ETH'"
            src="@/assets/icons/cryptocurrency/ETH.svg"
            alt="ETH coin"
            class="h-8 w-8 ml-2"
          />
          <img
            v-else
            src="@/assets/icons/cryptocurrency/USDT.svg"
            alt="USDT coin"
            class="h-8 w-8 ml-2"
          />
        </div>
      </div>
      <div class="w-full border-b border-blue-450"></div>
      <div
        class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 text-center flex flex-col items-center space-y-8 "
      >
        <div class="text-16">
          Total Balance
          <Loader v-if="isLoading" :classes="'w-16 h-16 mx-auto mt-2'" />
          <div v-else class="text-32">{{ totalBalance }}</div>
        </div>
        <div class="text-14 max-w-xs">
          The minimum deposit is {{ minAmount }} {{ currency }}. Depositing any
          other crypto asset besides {{ currency }} to this address may result
          in the total loss of your funds. We are not responsible for any funds
          lost due to the deposit of a different asset.
        </div>
        <div class="text-18 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          Your Wallet Address
          <button
            ref="address"
            class="mt-1 flex flex-col sm:flex-row justify-center items-center text-gray-400 text-12 sm:text-14 mx-auto"
            @keydown.enter="copyAddress"
            @click="copyAddress"
          >
            {{ user.deposit_address }}
            <img
              v-if="showCopyIcon"
              src="@/assets/icons/copy.svg"
              alt="copy address"
              class="sm:ml-2 sm:w-8 sm:h-8 ml-1 w-6 h-6"
            />
            <img
              v-else
              src="@/assets/icons/copy_success.svg"
              alt="copy address"
              class="ml-2 w-8 h-8"
            />
          </button>
        </div>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import * as api from '@/api';
import ModalWrapper from '@/components/modals/ModalWrapper';
import { formatCrypto } from '@/utils/decimal';
import { emitError } from '@/utils/alerts';
import Loader from '@/components/Loader';

export default {
  name: 'DepositModal',
  components: {
    ModalWrapper,
    Loader,
  },
  props: {
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: true,
      totalBalance: '0',
      showCopyIcon: true,
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
    minAmount() {
      return this.currency === 'ETH' ? '0.00001' : '0.01';
    },
  },
  async mounted() {
    if (!this.user.name) {
      this.onClose();
      return;
    }
    this.$refs.address.focus();
    try {
      this.isLoading = true;
      const balances = await api.get('balances');
      this.totalBalance = formatCrypto(balances[this.currency], this.currency);
      this.isLoading = false;
    } catch (e) {
      emitError('Error getting balances');
    }
  },
  methods: {
    copyAddress() {
      navigator.clipboard
        .writeText(this.user.deposit_address)
        .then(() => {
          this.showCopyIcon = false;
        })
        .catch(() => {
          emitError('Failed to copy address');
        });
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
