<template>
  <div>
    <WithdrawModal
      v-if="withdrawModal.isOpen"
      :currency="withdrawModal.currency"
      @close="closeWithdrawModal"
    />
    <WithdrawModalNFT
      v-if="withdrawModalNFT.isOpen"
      @close="closeWithdrawModalNFT"
    />
    <DepositModal
      v-if="depositModal.isOpen"
      :currency="depositModal.currency"
      @close="closeDepositModal"
    />
    <nav class="bg-blue-450">
      <div class="mx-auto px-4 ">
        <div class="flex justify-between h-20">
          <div class="inset-y-0 left-0 flex items-center mdd:hidden">
            <!-- Mobile menu button-->
            <button
              class="inline-flex items-center justify-center p-2 rounded-md text-gray-50 text-32"
              :aria-expanded="menuIsOpened"
              @click="toggleMenu"
            >
              <span class="sr-only">Open main menu</span>
              <!-- Icon when menu is closed. -->
              <!--
              Heroicon name: outline/menu

              Menu open: "hidden", Menu closed: "block"
            -->
              <svg
                v-if="!menuIsOpened"
                class="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <!-- Icon when menu is open. -->
              <!--
              Heroicon name: outline/x

              Menu open: "block", Menu closed: "hidden"
            -->
              <svg
                v-if="menuIsOpened"
                class="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          <div class="flex flex-grow justify-between">
            <div
              class="hidden ml-2 lg:ml-12 mdd:flex space-x-2 lg:space-x-4 flex-grow"
            >
              <router-link
                to="/"
                :exact="true"
                class="border-blue-500 text-gray-50 inline-flex items-center px-1 pt-1 border-b-2 text-18 font-medium hover:border-gray-100 focus-within:border-gray-100"
                active-class="border-blue-700"
              >
                <button class="outline-none font-medium">
                  Marketplace
                </button>
              </router-link>
              <CollectionButton />
              <router-link
                to="/activity"
                :exact="true"
                class="border-blue-500 text-gray-50 inline-flex items-center px-1 pt-1 border-b-2 text-18 font-medium hover:border-gray-100 focus-within:border-gray-100"
                active-class="border-blue-700"
              >
                <button class="outline-none font-medium">
                  Activity
                </button>
              </router-link>

              <SearchBar />
            </div>
            <div class="hidden mdd:flex justify-self-end sm:space-x-2">
              <NavBarActionButton
                v-if="!!user.email"
                action="deposit"
                @open-modal="openDepositModal"
              />
              <NavBarActionButton
                v-if="!!user.email"
                action="withdraw"
                @open-modal="openWithdrawModal"
                @open-nft-withdraw-modal="openWithdrawModalNFT"
              />
            </div>
          </div>
          <div
            class="right-0 flex static inset-auto items-center sm:ml-4 space-x-3"
          >
            <NotificationsButton v-if="user.email" />
            <SignInButton v-if="!user.email" />
            <AccountButton v-else :user="user" />
          </div>
        </div>
      </div>

      <!--
      Mobile menu, toggle classes based on menu state.

      Menu open: "block", Menu closed: "hidden"
      -->
      <div :class="menuClass">
        <div class="px-2 pt-2 pb-3 space-y-1 text-gray-100">
          <SearchBar :mobile="true" @token-chosen="toggleMenu" />
          <router-link
            to="/"
            :exact="true"
            active-class="bg-blue-600 border-blue-700 text-gray-0"
            class="border-transparent hover:bg-blue-700 hover:border-gray-300 hover:text-gray-50 block pl-3 pr-4 py-2 border-l-4 text-16 font-medium"
            @click.native="toggleMenu"
          >
            Marketplace
          </router-link>
          <CollectionButton
            :collections="collections"
            mobile
            @collection-chosen="toggleMenu"
          />
          <router-link
            to="/activity"
            :exact="true"
            active-class="bg-blue-600 border-blue-700 text-gray-0"
            class="border-transparent hover:bg-blue-700 hover:border-gray-300 hover:text-gray-50 block pl-3 pr-4 py-2 border-l-4 text-16 font-medium"
            @click.native="toggleMenu"
          >
            Activity
          </router-link>

          <NavBarActionButton
            v-if="!!user.email"
            action="deposit"
            mobile
            @open-modal="openDepositModal"
          />
          <NavBarActionButton
            v-if="!!user.email"
            action="withdraw"
            mobile
            @open-modal="openWithdrawModal"
            @open-nft-withdraw-modal="openWithdrawModalNFT"
          />
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import SignInButton from '@/components/buttons/SignInButton';
import AccountButton from '@/components/buttons/AccountButton';
import NavBarActionButton from '@/components/buttons/NavBarActionButton';
import WithdrawModal from '@/components/modals/WithdrawModal';
import WithdrawModalNFT from '@/components/modals/WithdrawModalNFT';
import CollectionButton from '@/components/buttons/CollectionButton';
import SearchBar from '@/components/SearchBar';
import DepositModal from '@/components/modals/DepositModal';
import NotificationsButton from '@/components/buttons/NotificationsButton';

import { mapGetters } from 'vuex';
import redirect from '@/utils/redirect';

export default {
  name: 'Navbar',
  components: {
    DepositModal,
    SignInButton,
    AccountButton,
    CollectionButton,
    NavBarActionButton,
    WithdrawModal,
    WithdrawModalNFT,
    SearchBar,
    NotificationsButton,
  },
  data() {
    return {
      collections: [],
      menuIsOpened: false,
      withdrawModal: {
        isOpen: false,
        currency: '',
      },
      depositModal: {
        isOpen: false,
        currency: '',
      },
      withdrawModalNFT: {
        isOpen: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
    menuClass() {
      if (this.menuIsOpened) {
        return 'block mdd:hidden';
      } else {
        return 'hidden mdd:hidden';
      }
    },
  },
  methods: {
    openDepositModal(currency = '', mobile) {
      if (mobile) {
        this.toggleMenu();
      }
      this.depositModal.isOpen = true;
      this.depositModal.currency = currency;
    },
    closeDepositModal() {
      this.depositModal.isOpen = false;
    },
    openWithdrawModal(currency, mobile) {
      if (mobile) {
        this.toggleMenu();
      }
      this.withdrawModal.isOpen = true;
      this.withdrawModal.currency = currency;
    },
    closeWithdrawModal() {
      this.withdrawModal.isOpen = false;
    },
    openWithdrawModalNFT(mobile) {
      if (mobile) {
        this.toggleMenu();
      }
      this.withdrawModalNFT.isOpen = true;
    },
    closeWithdrawModalNFT() {
      this.withdrawModalNFT.isOpen = false;
    },
    goHome() {
      //redirect only to other page
      redirect({
        name: 'Home',
      });
    },
    toggleMenu() {
      this.menuIsOpened = !this.menuIsOpened;
    },
  },
};
</script>
