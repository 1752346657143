<template>
  <div
    class="block md:p-4 p-2 hover:bg-gray-50 focus:outline-none focus:bg-gray-50 relative cursor-pointer"
    @click="onNotifClick(notificationMessage, hide)"
  >
    <div class="text-gray-800 flex justify-between items-center">
      <div>
        <p
          class="max-w-xs"
          :class="notification.is_read ? 'font-medium' : 'font-bold'"
        >
          {{ notificationMessage.content }}
        </p>
        <p class="text-12 mt-1 font-medium">{{ renderTime }}</p>
      </div>
    </div>
    <button
      v-if="!notification.is_read"
      class="absolute right-6 top-1/2 w-2 h-2 rounded-full bg-blue-100 hover:bg-blue-400 cursor-pointer"
      @click="onDotClick($event, notification)"
    ></button>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import * as api from '@/api';
import appNotificationsHandlers from '@/utils/appNotificationsHandlers';
import { differenceInHours, format, formatDistance } from 'date-fns';
import redirect from '@/utils/redirect';

export default {
  name: 'NotificationsButton',
  props: {
    notification: {
      type: Object,
      required: true,
    },
    hide: {
      type: Function,
      required: true,
    },
  },

  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
    notificationMessage() {
      return appNotificationsHandlers(this.notification);
    },
    renderTime() {
      const created_at = new Date(this.notification.created_at);
      if (differenceInHours(new Date(), created_at) < 24) {
        return formatDistance(new Date(), created_at) + ' ago';
      } else {
        return format(created_at, 'dd.MM.yy, HH:mm:ss');
      }
    },
  },

  methods: {
    onNotifClick(notificationMessage) {
      redirect({
        name: notificationMessage.pathName,
        params: notificationMessage.params,
      });
      this.hide();
    },
    async onDotClick(event, notification) {
      event.stopPropagation();
      await api.post(`notifications/${notification.id}/mark-as-read`);
      this.$emit('mark-as-read');
    },
  },
};
</script>
