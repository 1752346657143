<template>
  <div id="app">
    <ApproveAccount
      v-if="showApproveAccount"
      :authsec-url="authsecUrl"
      :confirmation-id="confirmationId"
    />

    <Navbar ref="navbar" />

    <div class="relative w-full">
      <Alert
        v-if="alert.type"
        :header="alert.header"
        :message="alert.message"
        :alert-type="alert.type"
      />
    </div>

    <router-view />
    <div class="h-56">
      <Footer />
    </div>
  </div>
</template>

<script>
import Navbar from './components/Navbar';
import Alert from '@/components/Alert';
import eventBus from './utils/eventBus';
import Footer from '@/components/Footer';
import ApproveAccount from '@/components/modals/ApproveAccount';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'App',
  components: { ApproveAccount, Navbar, Alert, Footer },
  data() {
    return {
      showApproveAccount: false,
      alert: {},
      authsecUrl: '',
      confirmationId: '',
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
  },
  watch: {
    $route() {
      if (this.user.name) {
        this.getUser();
      }
    },
  },

  mounted() {
    if (this.user.name) {
      this.getUser();
    }
    eventBus.$on('open-alert', this.openAlert);
    eventBus.$on('close-alert', this.closeAlert);
    eventBus.$on('open-approve-account', this.openApproveAccount);
    eventBus.$on('close-approve-account', this.closeApproveAccount);
    eventBus.$on('open-deposit-modal', this.$refs.navbar.openDepositModal);
  },
  methods: {
    ...mapActions({
      getUser: 'user/GET_USER_DATA',
    }),
    closeAlert() {
      this.alert = {};
    },
    openAlert(alert) {
      this.alert = alert;
      this.$forceUpdate();
      window.scrollTo(document.body.scrollWidth, 0);
    },
    openApproveAccount(authsecUrl = '', confirmationId = '') {
      this.authsecUrl = authsecUrl;
      this.confirmationId = confirmationId;
      this.showApproveAccount = true;
    },
    closeApproveAccount() {
      this.showApproveAccount = false;
    },
  },
};
</script>
<style>
#app {
  position: relative;
  min-width: 320px;
  min-height: 100vh;
}
</style>
