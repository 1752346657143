<template>
  <ModalWrapper @close="onClose">
    <div
      class="inline-block align-bottom bg-gray-0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full relative"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <button class="absolute right-5 top-5" @click="onClose">
        <img src="@/assets/icons/x.svg" alt="close" class="w-8 h-8" />
      </button>
      <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="flex items-center text-24">
          {{ currency }}
          <img
            v-if="currency === 'ETH'"
            src="@/assets/icons/cryptocurrency/ETH.svg"
            alt="ETH coin"
            class="h-8 w-8 ml-2"
          />
          <img
            v-else
            src="@/assets/icons/cryptocurrency/USDT.svg"
            alt="USDT coin"
            class="h-8 w-8 ml-2"
          />
        </div>
      </div>
      <div class="w-full border-b border-blue-450"></div>
      <div
        class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 text-center flex flex-col items-center"
      >
        <div class="text-16">
          Total Balance
          <Loader v-if="loading" :classes="'w-16 h-16 mx-auto mt-2'" />
          <div v-else class="text-24 mt-2">{{ totalBalance }}</div>
        </div>

        <form
          v-if="step === 1"
          class="text-18 px-4 pt-5 pb-4 sm:p-6 sm:pb-4 mt-2 space-y-6"
        >
          <div class="text-center">
            <label for="amount">
              Amount
            </label>
            <t-input id="amount" ref="amount" v-model="amount" type="number" />

            <p class="text-left text-12 mt-1">Withdrawal fee {{ fee }}</p>
            <p class="text-red-500">{{ amountError }}</p>
          </div>
          <div class="text-center">
            <label for="address">
              Withdrawal Address
            </label>
            <t-input id="address" v-model="address" />
            <p class="text-red-500">{{ addressError }}</p>
          </div>
          <div class="max-w-max mx-auto">
            <t-button @click.prevent="withdraw">
              Withdraw
            </t-button>
          </div>
        </form>
        <ApproveActionModal
          v-else
          :confirmation-id="confirmation_id"
          @close="onClose"
        />
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import ApproveActionModal from '@/components/modals/ApproveActionModal';
import ModalWrapper from '@/components/modals/ModalWrapper';
import * as api from '@/api';
import { formatCrypto } from '@/utils/decimal';
import Loader from '@/components/Loader';
import { mapGetters } from 'vuex';

export default {
  name: 'WithdrawModal',
  components: {
    ApproveActionModal,
    ModalWrapper,
    Loader,
  },
  props: {
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      step: 1,
      confirmation_id: '',
      loading: true,
      totalBalance: '',
      fee: '0.0',
      address: '',
      addressError: '',
      amount: '',
      amountError: '',
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
  },
  async mounted() {
    this.$refs.amount.focus();
    if (!this.user) {
      this.onClose();
      return;
    }
    try {
      this.loading = true;

      const balances = await api.get('balances');
      const fees = await api.get('withdrawal-fees');
      this.fee = formatCrypto(fees[this.currency], this.currency);

      this.totalBalance = formatCrypto(balances[this.currency], this.currency);
      this.loading = false;
    } catch (e) {
      this.onClose();
    }
  },
  methods: {
    async withdraw() {
      try {
        const { confirmation_id } = await api.post('withdraw', {
          currency: this.currency,
          amount: this.amount,
          address: this.address,
        });
        this.confirmation_id = confirmation_id;
        this.step++;
      } catch (e) {
        const errors = e.response.data;
        if (errors) {
          this.amountError = (errors.amount || []).join(' ');
          this.addressError = (errors.address || []).join(' ');
        }
        if (e.response.status === 403 && errors.detail) {
          this.amountError = errors.detail;
        }
      }
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
