export function getUser(state) {
  return {
    email: state.email,
    name: state.name,
    is_staff: state.is_staff,
    deposit_address: state.deposit_address,
    state: state.state,
    eth_support_enabled: state.eth_support_enabled,
  };
}
