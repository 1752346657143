<template>
  <div
    class="relative flex items-center flex-grow mdd:ml-4 mdd:pr-3 max-w-250px"
  >
    <button v-if="!mobile" class="llg:hidden" @click="openSearchBar">
      <img
        src="@/assets/icons/search_white.svg"
        alt="search"
        class="h-7 w-7 text-transparent"
      />
    </button>
    <div class="absolute top-16 hidden mdd:block llg:hidden w-56 z-10">
      <v-select
        v-if="isOpenSearchBar"
        id="token"
        v-model="token"
        class="v-search llg:w-full min-w-min"
        label="name"
        :placeholder="'Search NFTs'"
        :options="assetsOptions"
        :filterable="false"
        @open="onOpen"
        @close="onClose"
        @search="query => (search = query)"
        @input="redirectToDetails"
      >
        <template #option="{ name, asset_medium }">
          <div class="flex w-full items-center">
            <img
              :src="asset_medium"
              :alt="name + ' token'"
              class="w-6 h-6 mr-2 text-transparent"
            />
            <h4 style="margin: 0" class="truncate">{{ name }}</h4>
          </div>
        </template>
        <template v-slot:no-options="{ searching }">
          <template v-if="isLoading && searching">
            <Loader :classes="'w-10 h-10 mx-auto'" />
          </template>
          <em v-else style="opacity: 0.5">
            No tokens found
          </em>
        </template>
        <template #search="{ attributes, events }">
          <input
            ref="searchBar"
            class="vs__search"
            v-bind="attributes"
            @blur="isOpenSearchBar = false"
            v-on="events"
          />
        </template>

        <template #open-indicator="{ attributes }">
          <span v-bind="attributes">
            <!-- no img inside component /> -->
          </span>
        </template>
        <template #list-footer>
          <li v-show="hasMore && !isLoading" ref="load">
            <Loader :classes="'w-10 h-10 mx-auto'" />
          </li>
        </template>
      </v-select>
    </div>
    <v-select
      id="token"
      v-model="token"
      :class="{ 'hidden llg:block': !mobile }"
      class="v-search w-full"
      label="name"
      transition=""
      :placeholder="'Search NFTs'"
      :options="assetsOptions"
      :filterable="false"
      @open="onOpen"
      @close="onClose"
      @search="query => (search = query)"
      @input="redirectToDetails"
    >
      <template #option="{ name, asset_medium }">
        <div class="flex w-full items-center">
          <img
            :src="asset_medium"
            :alt="name + ' token'"
            class="w-6 h-6 mr-2 text-transparent"
          />
          <h4 style="margin: 0" class="truncate">{{ name }}</h4>
        </div>
      </template>
      <template v-slot:no-options="{ searching }">
        <template v-if="isLoading && searching">
          <Loader :classes="'w-10 h-10 mx-auto'" />
        </template>
        <em v-else style="opacity: 0.5">
          No tokens found
        </em>
      </template>
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <img
            src="@/assets/icons/search.svg"
            alt="search"
            class="h-6 w-6 text-transparent"
          />
        </span>
      </template>
      <template #search="{ attributes, events }">
        <input
          ref="searchBar"
          class="vs__search"
          v-bind="attributes"
          @blur="isOpenSearchBar = false"
          v-on="events"
        />
      </template>
      <template #list-footer>
        <li v-show="hasMore && !isLoading" ref="load">
          <Loader :classes="'w-10 h-10 mx-auto'" />
        </li>
      </template>
    </v-select>
  </div>
</template>

<script>
import * as api from '@/api';
import { emitError } from '@/utils/alerts';
import { mapGetters } from 'vuex';
import Loader from '@/components/Loader';

export default {
  name: 'WithdrawModal',
  components: { Loader },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      token: null,
      assetsOptions: [],
      search: '',
      timer: null,
      isLoading: false,
      isOpenSearchBar: false,
      busy: false,
      hasMore: true,
      lastPage: 0,
      observer: new IntersectionObserver(this.fetchAssets),
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
  },
  watch: {
    search() {
      this.assetsOptions = [];
      this.hasMore = true;
      this.lastPage = 0;
      this.fetchDebounce();
    },
  },
  async mounted() {
    this.fetchAssets();
  },
  methods: {
    async onOpen() {
      if (this.hasMore) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    openSearchBar() {
      this.isOpenSearchBar = !this.isOpenSearchBar;
      this.$nextTick(() => {
        this.$refs.searchBar.focus();
      });
    },
    onClose() {
      this.$emit('close');
      this.observer.disconnect();
    },
    redirectToDetails(token) {
      this.$emit('token-chosen');
      if (
        this.$route.name !== 'TokenDetails' ||
        this.$route.params.id !== token.id
      ) {
        this.$router.push({ name: 'TokenDetails', params: { id: token.id } });
      }
    },

    fetchDebounce() {
      this.isLoading = true;

      //debounce fetch to prevent multiple requests
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.fetchAssets();
      }, 1000);
    },

    async fetchAssets(
      [{ isIntersecting, target }] = [{ isIntersecting: false, target: null }],
    ) {
      if (this.busy || !this.hasMore) return;
      this.busy = true;
      this.isLoading = true;

      try {
        const { count, results } = await api.get('tokens', {
          state: 'FOR_SALE',
          search: this.search,
          page: this.lastPage + 1,
        });
        this.assetsOptions = this.assetsOptions.concat(results);
        this.hasMore = count > this.assetsOptions.length;

        this.lastPage++;
      } catch (e) {
        emitError(e);
        this.onClose();
      }
      if (isIntersecting) {
        const ul = target.parentElement;
        const scrollTop = target.parentElement.scrollTop;
        this.limit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
      this.busy = false;
      this.isLoading = false;
    },
  },
};
</script>
<style>
.v-search .vs__dropdown-toggle .vs__clear {
  display: none;
}

@media screen and (min-width: 880px) {
  .max-w-250px {
    max-width: 250px;
  }
}
</style>
