<template>
  <ModalWrapper @close="onClose">
    <div
      class="inline-block align-bottom bg-gray-0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full relative"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <button class="absolute right-5 top-5" @click="onClose">
        <img src="@/assets/icons/x.svg" alt="close" class="w-8 h-8" />
      </button>
      <div
        class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-col items-center space-y-5"
      >
        <h2 class="text-48 mb-10 text-center">
          Sign In
        </h2>

        <form v-if="step === 1" class="w-full px-10 flex flex-col space-y-10">
          <div class="text-center">
            Email address
            <t-input id="email" ref="email" v-model="email" />
            <p class="text-red-500">{{ error }}</p>
          </div>
          <div class="flex flex-col items-center space-y-4">
            <div class="max-w-max mx-auto">
              <t-button @click.prevent="signIn">
                Submit
              </t-button>
            </div>
            <div class="text-14">
              Don't have an account?
              <span
                class="font-bold text-blue-450 cursor-pointer"
                @click="showSignUpModal"
                >Sign up</span
              >
            </div>
          </div>
        </form>
        <ApproveActionModal
          v-else
          :confirmation-id="confirmation_id"
          :login-email="email"
          @close="onClose"
        />
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import * as api from '@/api';

import ModalWrapper from '@/components/modals/ModalWrapper';
import ApproveActionModal from '@/components/modals/ApproveActionModal';
import eventBus from '@/utils/eventBus';

export default {
  name: 'SignInModal',
  components: {
    ModalWrapper,
    ApproveActionModal,
  },
  props: {
    propEmail: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      step: 1,
      email: '',
      error: '',
      confirmation_id: '',
    };
  },
  mounted() {
    this.email = this.propEmail;
    this.$refs.email.focus();
    if (this.propEmail !== '') {
      this.signIn();
    }
  },
  methods: {
    async signIn() {
      this.error = '';
      try {
        const { confirmation_id } = await api.post('login', {
          email: this.email,
        });
        this.confirmation_id = confirmation_id;
        this.step++;
      } catch (e) {
        if (e.response.data) {
          this.error = (e.response.data.email || []).join(' ');
          if (this.error === 'The email address is not verified.') {
            this.onClose();
            eventBus.$emit('open-approve-account');
          }
        }
      }
    },
    showSignUpModal() {
      this.$emit('show-sign-up-modal');
      this.onClose();
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
