import { formatCrypto } from './decimal';
import { truncate } from './truncate';

const EVENTS_MAP = {
  SALE: n => ({
    pathName: 'UserTokenList',
    content: `You just bought NFT: ${truncate(n.token.name, 16)}`,
    params: {},
  }),

  BID: n => ({
    pathName: 'UserBidList',
    content: `You placed an offer to buy NFT: ${truncate(n.token.name, 16)}`,
    params: {},
  }),

  BID_COMPLETED: n => ({
    content: `Your offer has been accepted. NFT ${truncate(
      n.token.name,
      16,
    )} is yours!`,
    pathName: 'UserTokenList',
    params: {},
  }),

  BID_OUTBID: n => ({
    content: `Your offer for NFT ${truncate(
      n.token.name,
      16,
    )} has been outbid. Your funds have been released from escrow.`,
    pathName: 'TokenDetails',
    params: { id: n.token.id },
  }),

  BID_CANCELLED: n => ({
    content: `You cancelled your offer to buy NFT: ${truncate(
      n.token.name,
      16,
    )}. Your funds have been released from escrow.`,
    pathName: 'TokenDetails',
    params: { id: n.token.id },
  }),

  UNPUBLISH: n => ({
    content: `NFT ${truncate(
      n.token.name,
      16,
    )} is no longer available for sale. Your funds have been released from escrow.`,
    pathName: 'UserWalletList',
    params: {},
  }),

  WITHDRAWAL_NFT_FAILED: n => ({
    content: `Withdrawal of NFT ${truncate(
      n.token.name,
      16,
    )} has failed. Please try again or contact support.`,
    pathName: 'UserWalletList',
    params: {},
  }),

  WITHDRAWAL_CRYPTO_FAILED: n => ({
    content: `Withdrawal of ${formatCrypto(
      n.wallet_operation.amount,
      n.wallet_operation.currency,
    )} has failed. Please try again or contact support.`,
    pathName: 'UserWalletList',
    params: {},
  }),

  DEPOSIT_COMPLETED: () => ({
    content: `A new deposit has just been transferred to your account!`,
    pathName: 'UserWalletList',
    params: {},
  }),
};

export default function getAppNotification(notification) {
  const type = notification.event.kind;
  const handler = EVENTS_MAP[type];

  if (!handler) {
    console.error(`Unknown notification: ${type}`);
    return { link: '', label: 'Unknown notification' };
  }
  return handler(notification.event);
}
