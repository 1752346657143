var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-2"},[_c('menu-nav-dropdown',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
      var mousedownHandler = ref.mousedownHandler;
      var focusHandler = ref.focusHandler;
      var blurHandler = ref.blurHandler;
      var keydownHandler = ref.keydownHandler;
return _c('div',{staticClass:"flex"},[_c('t-nav-button',{staticClass:"min-width-100 px-3",on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler,"click":function($event){$event.preventDefault();return _vm.focusFirstButton.apply(null, arguments)}}},[_c('div',{staticClass:"border-b-2 border-transparent mx-auto truncate w-24"},[_vm._v(" "+_vm._s(_vm.accountDisplay)+" ")])])],1)}},{key:"default",fn:function(ref){
      var hide = ref.hide;
      var blurHandler = ref.blurHandler;
return _c('form',{staticClass:"z-30"},[_c('button',{ref:"firstbutton",staticClass:"account-option",attrs:{"role":"menuitem"},on:{"blur":blurHandler,"click":function($event){$event.preventDefault();return _vm.onViewMyAccount(hide)}}},[_vm._v(" My Account ")]),_c('hr'),_c('button',{staticClass:"account-option",attrs:{"role":"menuitem"},on:{"blur":blurHandler,"click":function($event){$event.preventDefault();return _vm.onViewMyWallets(hide)}}},[_vm._v(" My Wallets ")]),_c('hr'),_c('button',{staticClass:"account-option",attrs:{"role":"menuitem"},on:{"blur":blurHandler,"click":function($event){$event.preventDefault();return _vm.onViewMyNFTs(hide)}}},[_vm._v(" My NFTs ")]),_c('hr'),_c('button',{staticClass:"account-option",attrs:{"role":"menuitem"},on:{"blur":blurHandler,"click":function($event){$event.preventDefault();return _vm.onViewMyCollections(hide)}}},[_vm._v(" My Collections ")]),_c('hr'),_c('button',{staticClass:"account-option",attrs:{"role":"menuitem"},on:{"blur":blurHandler,"click":function($event){$event.preventDefault();return _vm.onViewMyBids(hide)}}},[_vm._v(" My Bids ")]),_c('hr'),(_vm.user.is_staff)?_c('button',{staticClass:"account-option",attrs:{"role":"menuitem"},on:{"blur":blurHandler,"click":function($event){$event.preventDefault();return _vm.onViewAdminPanel(hide)}}},[_vm._v(" Admin Panel ")]):_vm._e(),(_vm.user.is_staff)?_c('hr'):_vm._e(),_c('button',{staticClass:"account-option",attrs:{"role":"menuitem"},on:{"blur":blurHandler,"click":function($event){$event.preventDefault();return _vm.onLogout(hide)}}},[_vm._v(" Logout ")])])}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }