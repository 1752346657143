<template>
  <div class="m-2">
    <menu-nav-dropdown>
      <div
        slot="trigger"
        slot-scope="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
        class="flex"
      >
        <t-nav-button
          class="min-width-100 px-3"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
          @click.prevent="focusFirstButton"
        >
          <div class="border-b-2 border-transparent mx-auto truncate w-24">
            {{ accountDisplay }}
          </div>
        </t-nav-button>
      </div>
      <form slot-scope="{ hide, blurHandler }" class="z-30">
        <button
          ref="firstbutton"
          class="account-option"
          role="menuitem"
          @blur="blurHandler"
          @click.prevent="onViewMyAccount(hide)"
        >
          My Account
        </button>
        <hr />
        <button
          class="account-option"
          role="menuitem"
          @blur="blurHandler"
          @click.prevent="onViewMyWallets(hide)"
        >
          My Wallets
        </button>
        <hr />
        <button
          class="account-option"
          role="menuitem"
          @blur="blurHandler"
          @click.prevent="onViewMyNFTs(hide)"
        >
          My NFTs
        </button>
        <hr />
        <button
          class="account-option"
          role="menuitem"
          @blur="blurHandler"
          @click.prevent="onViewMyCollections(hide)"
        >
          My Collections
        </button>
        <hr />
        <button
          class="account-option"
          role="menuitem"
          @blur="blurHandler"
          @click.prevent="onViewMyBids(hide)"
        >
          My Bids
        </button>
        <hr />

        <button
          v-if="user.is_staff"
          class="account-option"
          role="menuitem"
          @blur="blurHandler"
          @click.prevent="onViewAdminPanel(hide)"
        >
          Admin Panel
        </button>
        <hr v-if="user.is_staff" />
        <button
          class="account-option"
          role="menuitem"
          @blur="blurHandler"
          @click.prevent="onLogout(hide)"
        >
          Logout
        </button>
      </form>
    </menu-nav-dropdown>
  </div>
</template>
<script>
import redirect from '@/utils/redirect';

export default {
  name: 'AccountButton',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },

  computed: {
    accountDisplay() {
      return this.user.name;
    },
  },

  methods: {
    focusFirstButton() {
      this.$refs.firstbutton.focus();
    },
    onViewMyAccount(hide) {
      redirect({ name: 'UserAccount' });

      hide();
    },
    onViewMyWallets(hide) {
      redirect({ name: 'UserWalletList' });

      hide();
    },
    onViewMyNFTs(hide) {
      redirect({ name: 'UserTokenList' });

      hide();
    },
    onViewMyCollections(hide) {
      redirect({ name: 'UserCollectionList' });

      hide();
    },
    onViewMyBids(hide) {
      redirect({ name: 'UserBidList' });

      hide();
    },
    onViewAdminPanel() {
      const baseUrl =
        process.env.NODE_ENV === 'development' ? 'http://localhost:8002' : '';
      location = `${baseUrl}/admin/`;
    },
    onLogout(hide) {
      redirect({ name: 'Logout' });
      hide();
    },
  },
};
</script>
<style scoped>
.logo {
  display: inline;
  height: 0.75rem;
}
.min-width-100 {
  min-width: 100px;
}
</style>
