import * as api from '@/api';

export async function GET_USER_DATA({ commit }) {
  const data = await api.get('user');
  localStorage.setItem('user', JSON.stringify(data));
  commit('SET_USER_DATA', data);
}

export async function LOGOUT_USER({ commit }) {
  localStorage.removeItem('user');
  try {
    await api.post('logout');
  } catch (e) {
    // Assume it's 401
  }
  commit('LOGOUT');
}

export async function LOGIN_USER({ commit }, data) {
  localStorage.setItem('user', JSON.stringify(data));
  commit('SET_USER_DATA', data);
}
