var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('notification-dropdown',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
    var mousedownHandler = ref.mousedownHandler;
    var focusHandler = ref.focusHandler;
    var blurHandler = ref.blurHandler;
    var keydownHandler = ref.keydownHandler;
return _c('div',{},[_c('t-notification-button',{ref:"navButton",staticClass:"box-10",on:{"mouseleave":function($event){return _vm.$refs.navButton.focus()},"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('img',{attrs:{"src":require("@/assets/icons/bell.svg"),"alt":"Notifications button"}}),(_vm.unreadNotifications)?_c('div',{staticClass:"absolute rounded-full flex justify-center items-center w-6 h-6 text-gray-0 bg-blue-300 -top-1.5 -right-1.5 text-10"},[_vm._v(" "+_vm._s(_vm.unreadNotifications > 99 ? '99+' : _vm.unreadNotifications)+" ")]):_vm._e()])],1)}},{key:"default",fn:function(ref){
    var hide = ref.hide;
    var blurHandler = ref.blurHandler;
return _c('div',{staticClass:"z-50 relative dropdown",on:{"blur":blurHandler}},[_c('div',{staticClass:"flex flex-wrap justify-between items-center text-gray-800 text-12"},[_c('div',{staticClass:"mr-4"},[_c('p',{staticClass:"text-32 font-light"},[_vm._v("Notifications")]),_c('p',[_vm._v("Recent")])]),_c('button',{staticClass:"uppercase font-extrabold",on:{"click":_vm.markAllAsRead}},[_vm._v(" mark all as read ")])]),_c('div',{staticClass:"overflow-y-auto divide-y divide-blue-700 divide-opacity-30  border-t-2 border-b-2 border-blue-700 border-opacity-30 mt-4",staticStyle:{"maxHeight":"369px"}},_vm._l((_vm.notifications),function(notification){return _c('AppNotificationItem',{key:notification.id,attrs:{"notification":notification,"hide":hide},on:{"mark-as-read":_vm.markAsRead}})}),1),_c('div',{staticClass:"flex flex-col items-center text-gray-800 mt-8 mb-2"},[(_vm.notifications.length)?_c('img',{staticClass:"w-7",attrs:{"src":require("@/assets/icons/double_check.svg"),"alt":"check"}}):_vm._e(),_c('p',{staticClass:"text-12 w-44 font-medium text-center mt-2"},[_vm._v(" "+_vm._s(_vm.notifications.length ? 'These are all your notifications from last 30 days' : "You don't have any notifications")+" ")])])])}}])})}
var staticRenderFns = []

export { render, staticRenderFns }