<template>
  <div>
    <div :class="buttonStyle" class="flex focus-within:border-gray-100 ">
      <t-nav-button
        v-if="mobile"
        class="min-width-83 items-center"
        classes="text-16 md:text-18 w-full text-left capitalize font-medium flex"
        @click="toggleMobileOptions"
      >
        Collection
      </t-nav-button>
      <nav-dropdown v-else class="justify-center w-full">
        <div
          slot="trigger"
          slot-scope="{
            mousedownHandler,
            focusHandler,
            blurHandler,
            keydownHandler,
            isShown,
          }"
        >
          <t-nav-button
            class="min-width-83 items-center"
            classes="text-16 md:text-18 w-full text-left capitalize font-medium flex"
            @mousedown="mousedownHandler"
            @focus="focusHandler"
            @blur="blurHandler"
            @keydown="keydownHandler"
            @click="focusButton(isShown)"
          >
            Collection
          </t-nav-button>
        </div>
        <form
          v-if="collections.length"
          ref="buttons"
          slot-scope="{ hide, blurHandler }"
          class="max-h-52 overflow-y-auto "
        >
          <div v-for="collection in collections" :key="collection.id">
            <button
              :key="$route.fullPath"
              class="account-option px-2 min-width-83 max-w-xs truncate"
              role="menuitem"
              @blur="blurHandler"
              @click.prevent="choseCollection(collection.name, hide)"
            >
              {{ collection.name }}
            </button>
            <hr />
          </div>
        </form>
      </nav-dropdown>
    </div>
    <div
      v-if="isOpenMoblieOptions && mobile"
      class="ml-3 mt-1 mb-3 bg-transparent text-gray-50 max-h-52 overflow-y-auto  space-y-1"
    >
      <button
        v-for="collection in collections"
        :key="collection.id"
        class="block text-left focus:bg-gray-50 hover:bg-gray-50 focus:text-blue-700 hover:text-blue-700 hover:bg-opacity-90 rounded-md py-0.5 px-3 max-w-full w-min truncate"
        role="menuitem"
        @click="choseCollection(collection.name)"
      >
        {{ collection.name }}
      </button>
    </div>
  </div>
</template>
<script>
import * as api from '@/api';
import redirect from '@/utils/redirect';

export default {
  name: 'CollectionButton',
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      collections: [],
      isOpenMoblieOptions: false,
    };
  },
  computed: {
    isActive() {
      return this.$route.path.startsWith('/collection');
    },
    buttonStyle() {
      let classes = '';
      if (this.mobile) {
        classes = this.isActive
          ? 'bg-blue-600 border-blue-700 text-gray-0 '
          : '';
        classes +=
          'border-transparent hover:bg-blue-700 hover:border-gray-300 hover:text-gray-50 block pl-3 pr-4 py-2 border-l-4 text-16 font-medium focus:border-gray-300';
      } else {
        classes = this.isActive ? 'border-blue-700 ' : '';
        classes +=
          'text-gray-50 inline-flex items-center pt-1 font-medium h-full border-transparent border-b-2 hover:border-gray-100';
      }

      return classes;
    },
  },
  async mounted() {
    await this.fetchCollections();
  },

  methods: {
    async focusButton(isShown) {
      if (isShown) {
        this.$refs.buttons.firstChild.firstChild.focus();
      }
      await this.fetchCollections();
    },
    async fetchCollections() {
      this.collections = await api.get('collections');
    },

    //redirect to right collection page
    choseCollection(collection, hide = false) {
      this.$emit('collection-chosen');
      redirect({
        name: 'CollectionDetails',
        params: {
          collection,
        },
        force: true,
      });
      if (hide) {
        hide();
      }
    },
    toggleMobileOptions() {
      this.isOpenMoblieOptions = !this.isOpenMoblieOptions;
    },
  },
};
</script>
<style scoped>
.logo {
  display: inline;
  height: 0.75rem;
}
.min-width-83 {
  min-width: 83px;
}
@media screen and (min-width: 768px) {
  .min-width-83 {
    min-width: 83px;
  }
}
</style>
