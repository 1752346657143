<template>
  <ModalWrapper @close="onClose">
    <div
      ref="modal"
      class="inline-block align-bottom bg-gray-0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full relative"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
      tabindex="0"
    >
      <button class="absolute right-5 top-5" @click="onClose">
        <img src="@/assets/icons/x.svg" alt="close" class="w-8 h-8" />
      </button>
      <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="flex items-center text-24">
          NFT
        </div>
      </div>
      <div class="w-full border-b border-blue-450"></div>
      <div
        class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 text-center flex flex-col items-center"
      >
        <form
          v-if="step === 1"
          class="text-18 px-4 pt-5 pb-4 sm:p-6 sm:pb-4 mt-2 space-y-6"
        >
          <div class="text-center">
            <label for="token">
              Choose NFT
            </label>
            <v-select
              id="token"
              v-model="token"
              label="name"
              class="v-search-my"
              :options="assetsOptions"
              :clerable="false"
              @open="onOpen"
              @search="query => (search = query)"
            >
              <template #option="{ name, asset_medium }">
                <div class="flex w-full items-center">
                  <img
                    :src="asset_medium"
                    :alt="name + ' token'"
                    class="w-6 h-6 mr-2"
                  />
                  <h4 style="margin: 0" class="truncate">{{ name }}</h4>
                </div>
              </template>

              <template v-slot:no-options="{ searching }">
                <template v-if="isLoading && searching">
                  <Loader :classes="'w-10 h-10 mx-auto'" />
                </template>
                <em v-else style="opacity: 0.5">
                  No tokens found
                </em>
              </template>
              <template #list-footer>
                <li v-show="hasMore && !isLoading" ref="load">
                  <Loader :classes="'w-10 h-10 mx-auto'" />
                </li>
              </template>
            </v-select>

            <p class="text-left text-12 mt-1">
              No withdrawal fee
            </p>
            <p class="text-red-500">{{ tokenError }}</p>
          </div>
          <div v-if="token && token.asset_medium" class="w-28 h-28 mx-auto">
            <TopImage :image="token.asset_medium" />
          </div>
          <div class="text-center">
            <label for="address">
              Withdrawal Address
            </label>
            <t-input id="address" v-model="address" />
            <p class="text-red-500">
              {{ addressError }}
            </p>
          </div>
          <div class="max-w-max mx-auto">
            <t-button @click.prevent="withdraw">
              Withdraw
            </t-button>
          </div>
        </form>
        <ApproveActionModal
          v-else
          :confirmation-id="confirmation_id"
          @close="onClose"
        />
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import ApproveActionModal from '@/components/modals/ApproveActionModal';
import ModalWrapper from '@/components/modals/ModalWrapper';
import TopImage from '@/components/TopImage';
import * as api from '@/api';
import Loader from '@/components/Loader';
import { emitError } from '@/utils/alerts';
import { mapGetters } from 'vuex';

export default {
  name: 'WithdrawModalNFT',
  components: {
    ApproveActionModal,
    ModalWrapper,
    TopImage,
    Loader,
  },

  data() {
    return {
      token: {},
      step: 1,
      confirmation_id: '',
      address: '',
      addressError: '',
      tokenError: '',
      assetsOptions: [],
      search: '',
      timer: null,
      isLoading: false,
      busy: false,
      hasMore: true,
      lastPage: 0,
      observer: new IntersectionObserver(this.fetchAssets),
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
  },
  watch: {
    search() {
      this.assetsOptions = [];
      this.hasMore = true;
      this.lastPage = 0;
      this.fetchDebounce();
    },
  },
  async mounted() {
    this.$refs.modal.focus();
    if (!this.user.name) {
      this.onClose();
      return;
    }
  },

  methods: {
    async onOpen() {
      if (this.hasMore) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.$emit('close');
      this.observer.disconnect();
    },
    async withdraw() {
      try {
        const { confirmation_id } = await api.post('withdraw', {
          currency: 'NFT',
          token: this.token.id,
          address: this.address,
        });
        this.confirmation_id = confirmation_id;
        this.step++;
      } catch (e) {
        const errors = e.response.data;
        if (errors) {
          this.tokenError = (errors.token || []).join(' ');
          this.addressError = (errors.address || []).join(' ');
        }
      }
    },
    fetchDebounce() {
      if (this.token === null) this.token = {};
      this.isLoading = true;

      //debounce fetch to prevent multiple requests
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.fetchAssets();
      }, 1000);
    },
    async fetchAssets(
      [{ isIntersecting, target }] = [{ isIntersecting: false, target: null }],
    ) {
      if (this.busy || !this.hasMore) return;
      this.busy = true;
      this.isLoading = true;

      try {
        const { count, results } = await api.get('tokens', {
          my_nfts: true,
          search: this.search,
          page: this.lastPage + 1,
        });
        this.assetsOptions = this.assetsOptions.concat(results);
        this.hasMore = count > this.assetsOptions.length;
        this.lastPage++;
      } catch (e) {
        emitError(e);
        this.onClose();
      }
      if (isIntersecting) {
        const ul = target.parentElement;
        const scrollTop = target.parentElement.scrollTop;
        this.limit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
      this.busy = false;
      this.isLoading = false;
    },
  },
};
</script>
<style>
.v-select .vs__dropdown-toggle .vs__clear {
  display: none;
}
</style>
