<template>
  <div class="flex flex-col items-center">
    <div class="mb-10 text-16 w-2/3 text-center">
      {{ content }}
    </div>
    <Loader />
    <a
      target="_blank"
      href="https://authsec.io/"
      :class="{ 'bg-green-1000': isImgLoaded }"
      class="px-3 rounded ml-2 mt-1.5 h-12 flex justify-center"
    >
      <img
        src="@/assets/icons/authsec_logo.svg"
        alt="AuthSec"
        class="w-24"
        @load="onLoad"
      />
    </a>
    <div v-if="loginEmail" class="text-14 mt-8">
      Don't have your phone? Send me a one-time login
      <span
        class="font-bold text-blue-450 cursor-pointer"
        @click="sendLoginOTPEmail"
        >email</span
      >
    </div>
  </div>
</template>
<script>
import * as api from '@/api';

import Loader from '@/components/Loader';
import { emitError, emitSucces } from '@/utils/alerts';

const CHECK_CONFIRMATION_MAX_RETRIES = 40;
const CHECK_CONFIRMATION_DELAY_SECONDS = 2;

export default {
  name: 'ApproveActionModal',
  components: {
    Loader,
  },
  props: {
    content: {
      default: 'Approve request using AuthSec app on your phone',
      type: String,
    },
    confirmationId: {
      type: String,
      required: true,
    },
    loginEmail: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      error: '',
      timer: null,
      actionConfirmationRetry: 0,
      isImgLoaded: false,
    };
  },
  mounted() {
    this.checkActionConfirmation();
  },
  beforeDestroy() {
    this.onClose();
  },
  methods: {
    onLoad() {
      this.isImgLoaded = true;
    },
    async checkActionConfirmation() {
      this.actionConfirmationRetry++;
      if (this.actionConfirmationRetry > CHECK_CONFIRMATION_MAX_RETRIES) {
        this.onExpire();
      }
      try {
        const { state } = await api.get(
          `authsec-confirmations/${this.confirmationId}`,
        );
        if (state === 'ACCEPTED') {
          if (this.loginEmail) {
            await this.loginUser();
          }
          this.onClose();
          return;
        }
        if (state === 'REJECTED') {
          emitError('Operation rejected');
          this.onClose();
          return;
        }
        if (state === 'FAILED') {
          emitError('Operation canceled');
          this.onClose();
          return;
        }
        if (state === 'EXPIRED') {
          this.onExpire();
        }
        this.timer = setTimeout(() => {
          this.checkActionConfirmation();
        }, CHECK_CONFIRMATION_DELAY_SECONDS * 1000);
      } catch (e) {
        emitError(e.response.data);
      }
    },
    async sendLoginOTPEmail() {
      try {
        await api.post('login-without-device', { email: this.loginEmail });
        emitSucces('Login email sent.');
        this.onClose();
      } catch (e) {
        emitError('Could not send email.');
        this.onClose();
      }
    },
    async loginUser() {
      const userData = await api.get('user');
      await this.$store.dispatch('user/LOGIN_USER', userData);
    },
    onClose() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.$emit('close');
    },
    onExpire() {
      emitError('Operation timeout');
      this.onClose();
    },
  },
};
</script>
