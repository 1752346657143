<template>
  <div class="w-full aspect-w-10 aspect-h-10 relative">
    <Loader
      v-if="isLoading"
      :classes="'object-cover rounded-lg w-1/2 h-1/2 absolute top-1/4 left-1/4'"
    />

    <img
      :src="image"
      :class="
        `text-transparent object-cover rounded-lg ${
          isLoading ? 'invisible' : ''
        }`
      "
      alt="NFT Logo"
      @load="onLoad"
    />
  </div>
</template>
<script>
import Loader from '@/components/Loader';

export default {
  name: 'TopImage',
  components: {
    Loader,
  },

  props: {
    image: {
      type: [String, Number],
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isLoading: true,
    };
  },
  methods: {
    onLoad() {
      this.isLoading = false;
    },
  },
};
</script>
