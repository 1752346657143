<template>
  <ModalWrapper @close="onClose">
    <div
      class="inline-block align-bottom bg-gray-0 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full relative"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <button class="absolute right-5 top-5" @click="onClose">
        <img src="@/assets/icons/x.svg" alt="close" class="w-8 h-8" />
      </button>
      <div class="p-6 sm:py-10 flex flex-col items-center space-y-5">
        <h2 class="text-32 mb-8 text-center">
          Thank you for signing up!
        </h2>
        <div
          class="flex flex-col justify-center items-center space-y-5 mb-4 sm:mb-9"
        >
          <p class="text-20">Follow these steps to access your account:</p>

          <div
            v-if="step >= 1"
            class="flex
          "
          >
            <div class="relative text-32 ml-3 mr-6 sm:ml-10 my-auto">
              1
              <div v-if="step === 2" class="absolute w-7 h-7 right-5 top-1.5">
                <img
                  src="@/assets/icons/check.svg"
                  alt="check"
                  class="w-full h-full"
                />
              </div>
            </div>
            <div class="pt-1.5 pr-1.5">
              We’ve sent you an email with a confirmation link. Use it to
              confirm your address.
            </div>
          </div>
          <div
            v-if="step >= 1"
            :class="`flex ${step === 2 ? 'text-gray-800' : 'text-gray-400'}`"
          >
            <div class="relative text-32 ml-3 mr-6 sm:ml-10 my-auto">
              2
            </div>
            <div class="pt-1.5 mr-1">
              To secure your account, pair it with AuthSec app. You can download
              it from the store.
            </div>
            <a
              target="_blank"
              href="https://authsec.io/"
              :class="{ 'bg-green-1000': isImgLoaded }"
              class="px-3 rounded ml-2 mt-1.5 h-12 flex justify-center"
            >
              <img
                src="@/assets/icons/authsec_logo.svg"
                alt="AuthSec"
                class="w-64"
                @load="onLoad"
              />
            </a>
          </div>
          <div v-if="step === 2 && authsecUrl" class="text-center w-4/6 pt-4">
            Scan QR code using AuthSec app.
            <div class="p-2 border-2 rounded-sm transform scale-65 -mt-6">
              <VueQRCodeComponent
                :text="authsecUrl"
                :size="300"
                bg-color="#EEF1F4"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import ModalWrapper from '@/components/modals/ModalWrapper';
import VueQRCodeComponent from 'vue-qrcode-component';
import * as api from '@/api';
import { emitError } from '@/utils/alerts';
import eventBus from '@/utils/eventBus';
import redirect from '@/utils/redirect';

const CHECK_PAIR_DEVICE_INTERVAL_SECONDS = 2;
const CHECK_PAIR_DEVICE_MAX_RETRIES = 40;

export default {
  name: 'ApproveAccount',
  components: {
    ModalWrapper,
    VueQRCodeComponent,
  },
  props: {
    confirmationId: {
      type: String,
      default: '',
    },
    authsecUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      step: 1,
      checkRetry: 0,
      timer: null,
      isImgLoaded: false,
    };
  },
  mounted() {
    if (this.confirmationId && this.authsecUrl) {
      this.step++;
      this.checkPairDevice();
    }
  },
  beforeDestroy() {
    this.onClose();
  },
  methods: {
    onLoad() {
      this.isImgLoaded = true;
    },
    async checkPairDevice() {
      if (this.checkRetry > CHECK_PAIR_DEVICE_MAX_RETRIES) {
        this.onExpire();
        return;
      }
      try {
        this.checkRetry++;
        const { state } = await api.get(
          `authsec-confirmations/${this.confirmationId}`,
        );
        if (state === 'ACCEPTED') {
          clearTimeout(this.timer);
          const userData = await api.get('user');
          this.$store.dispatch('user/LOGIN_USER', userData);
          this.onClose();
          redirect({
            name: 'Home',
          });
          return;
        }
        if (state === 'REJECTED') {
          emitError('Pairing device rejected');
          this.onClose();
        }
        if (state === 'FAILED') {
          emitError('Pairing device canceled');
          this.onClose();
        }
        if (state === 'EXPIRED') {
          this.onExpire();
        }

        this.timer = setTimeout(() => {
          this.checkPairDevice();
        }, CHECK_PAIR_DEVICE_INTERVAL_SECONDS * 1000);
      } catch (e) {
        if (e.response.data) {
          const msg = (e.response.data.non_field_errors || []).join(' ');
          emitError(msg);
        }
        this.onClose();
      }
    },

    onClose() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      eventBus.$emit('close-approve-account');
    },
    onExpire() {
      emitError('Pair device timeout');
      this.onClose();
    },
  },
};
</script>
