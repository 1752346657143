var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative flex items-center flex-grow mdd:ml-4 mdd:pr-3 max-w-250px"},[(!_vm.mobile)?_c('button',{staticClass:"llg:hidden",on:{"click":_vm.openSearchBar}},[_c('img',{staticClass:"h-7 w-7 text-transparent",attrs:{"src":require("@/assets/icons/search_white.svg"),"alt":"search"}})]):_vm._e(),_c('div',{staticClass:"absolute top-16 hidden mdd:block llg:hidden w-56 z-10"},[(_vm.isOpenSearchBar)?_c('v-select',{staticClass:"v-search llg:w-full min-w-min",attrs:{"id":"token","label":"name","placeholder":'Search NFTs',"options":_vm.assetsOptions,"filterable":false},on:{"open":_vm.onOpen,"close":_vm.onClose,"search":function (query) { return (_vm.search = query); },"input":_vm.redirectToDetails},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var asset_medium = ref.asset_medium;
return [_c('div',{staticClass:"flex w-full items-center"},[_c('img',{staticClass:"w-6 h-6 mr-2 text-transparent",attrs:{"src":asset_medium,"alt":name + ' token'}}),_c('h4',{staticClass:"truncate",staticStyle:{"margin":"0"}},[_vm._v(_vm._s(name))])])]}},{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(_vm.isLoading && searching)?[_c('Loader',{attrs:{"classes":'w-10 h-10 mx-auto'}})]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v(" No tokens found ")])]}},{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({ref:"searchBar",staticClass:"vs__search",on:{"blur":function($event){_vm.isOpenSearchBar = false}}},'input',attributes,false),events))]}},{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false))]}},{key:"list-footer",fn:function(){return [_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasMore && !_vm.isLoading),expression:"hasMore && !isLoading"}],ref:"load"},[_c('Loader',{attrs:{"classes":'w-10 h-10 mx-auto'}})],1)]},proxy:true}],null,false,2046066688),model:{value:(_vm.token),callback:function ($$v) {_vm.token=$$v},expression:"token"}}):_vm._e()],1),_c('v-select',{staticClass:"v-search w-full",class:{ 'hidden llg:block': !_vm.mobile },attrs:{"id":"token","label":"name","transition":"","placeholder":'Search NFTs',"options":_vm.assetsOptions,"filterable":false},on:{"open":_vm.onOpen,"close":_vm.onClose,"search":function (query) { return (_vm.search = query); },"input":_vm.redirectToDetails},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var asset_medium = ref.asset_medium;
return [_c('div',{staticClass:"flex w-full items-center"},[_c('img',{staticClass:"w-6 h-6 mr-2 text-transparent",attrs:{"src":asset_medium,"alt":name + ' token'}}),_c('h4',{staticClass:"truncate",staticStyle:{"margin":"0"}},[_vm._v(_vm._s(name))])])]}},{key:"no-options",fn:function(ref){
var searching = ref.searching;
return [(_vm.isLoading && searching)?[_c('Loader',{attrs:{"classes":'w-10 h-10 mx-auto'}})]:_c('em',{staticStyle:{"opacity":"0.5"}},[_vm._v(" No tokens found ")])]}},{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('img',{staticClass:"h-6 w-6 text-transparent",attrs:{"src":require("@/assets/icons/search.svg"),"alt":"search"}})])]}},{key:"search",fn:function(ref){
var attributes = ref.attributes;
var events = ref.events;
return [_c('input',_vm._g(_vm._b({ref:"searchBar",staticClass:"vs__search",on:{"blur":function($event){_vm.isOpenSearchBar = false}}},'input',attributes,false),events))]}},{key:"list-footer",fn:function(){return [_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasMore && !_vm.isLoading),expression:"hasMore && !isLoading"}],ref:"load"},[_c('Loader',{attrs:{"classes":'w-10 h-10 mx-auto'}})],1)]},proxy:true}]),model:{value:(_vm.token),callback:function ($$v) {_vm.token=$$v},expression:"token"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }