import axios from 'axios';
import { emitError, emitSucces } from '@/utils/alerts';
import redirect from './utils/redirect';

const baseUrl =
  process.env.NODE_ENV === 'development' ? 'http://localhost:8002' : '';

export const axiosInstance = axios.create({
  baseURL: `${baseUrl}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-Csrftoken',
  withCredentials: true,
});

axiosInstance.interceptors.response.use(undefined, errorsHandler);

function errorsHandler(error) {
  if (!error.response) {
    emitError('Network error');

    return;
  }
  if (error.response.status === 401 && !error.config.url.endsWith('logout')) {
    emitSucces('You were logged out');

    redirect({ name: 'Logout' });

    return Promise.reject(error);
  }

  if (error.response.status !== 400 && error.response.status !== 401) {
    emitError(error.response.data);
  }

  return Promise.reject(error);
}

export async function get(path, params) {
  const response = await axiosInstance.get(path, { params });
  return response.data;
}

export async function post(path, params) {
  const response = await axiosInstance.post(path, params);
  return response.data;
}

export async function patch(path, params) {
  const response = await axiosInstance.patch(path, params);
  return response.data;
}

export async function remove(path, params) {
  const response = await axiosInstance.delete(path, params);
  return response.data;
}
