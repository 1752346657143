<template>
  <footer
    class="w-full absolute bottom-0 bg-gray-800 h-56 flex flex-col justify-evenly items-center"
  >
    <div class="flex text-center">
      <router-link
        to="/"
        :exact="true"
        class="block text-16 leading-6 font-normal text-gray-500 hover:text-gray-400 mx-5"
      >
        For Sale
      </router-link>
    </div>
    <div class="text-16 leading-6 font-normal text-gray-400">
      © {{ year }} Ulam Labs. All rights reserved.
    </div>
  </footer>
</template>
<script>
export default {
  name: 'Footer',
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>
