<template>
  <div>
    <SignInModal
      v-if="isSignInModalVisible"
      @close="hideSignInModal"
      @show-sign-up-modal="showSignUpModal"
    />
    <SignUpModal
      v-if="isSignUpModalVisible"
      @close="hideSignUpModal"
      @show-sign-in-modal="showSignInModal"
    />
    <t-nav-button @click="showSignInModal">
      Sign in
    </t-nav-button>
  </div>
</template>

<script>
import SignInModal from '@/components/modals/SignInModal';
import SignUpModal from '@/components/modals/SignUpModal';
import eventBus from '@/utils/eventBus';

export default {
  name: 'SignInButton',
  components: {
    SignInModal,
    SignUpModal,
  },
  data() {
    return {
      isSignInModalVisible: false,
      isSignUpModalVisible: false,
    };
  },
  mounted() {
    eventBus.$on('show-sign-in-modal', this.showSignInModal);
  },
  methods: {
    showSignInModal() {
      this.isSignInModalVisible = true;
    },
    hideSignInModal() {
      this.isSignInModalVisible = false;
    },
    showSignUpModal() {
      this.isSignUpModalVisible = true;
    },
    hideSignUpModal() {
      this.isSignUpModalVisible = false;
    },
  },
};
</script>
