import eventBus from '@/utils/eventBus';

export function emitError(message) {
  //Make sure not to show errors that dont tell anything to user
  if (message.detail) return;

  eventBus.$emit('open-alert', {
    type: 'error',
    message,
  });
}

export function emitSucces(message) {
  //Make sure not to show errors that dont tell anything to user
  if (message.detail) return;
  eventBus.$emit('open-alert', {
    type: 'success',
    message,
  });
}
