import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home'),
  },
  {
    path: '/nft/:id',
    name: 'TokenDetails',
    component: () => import('@/views/TokenDetails'),
  },
  {
    path: '/account',
    name: 'UserAccount',
    component: () => import('@/views/UserAccount'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('@/views/Logout'),
  },
  {
    path: '/my-nfts',
    name: 'UserTokenList',
    component: () => import('@/views/UserTokenList'),
  },
  {
    path: '/my-collections',
    name: 'UserCollectionList',
    component: () => import('@/views/UserCollectionList'),
  },
  {
    path: '/verify-email',
    name: 'Home',
    component: () => import('@/views/Home'),
  },
  {
    path: '/my-bids',
    name: 'UserBidList',
    component: () => import('@/views/UserBidList'),
  },
  {
    path: '/my-wallets',
    name: 'UserWalletList',
    component: () => import('@/views/UserWalletList'),
  },
  {
    path: '/collections/:collection',
    name: 'CollectionDetails',
    component: () => import('@/views/CollectionDetails'),
  },
  {
    path: '/activity',
    name: 'Activity',
    component: () => import('@/views/Activity'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
