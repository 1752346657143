<template>
  <notification-dropdown>
    <div
      slot="trigger"
      slot-scope="{
        mousedownHandler,
        focusHandler,
        blurHandler,
        keydownHandler,
      }"
    >
      <t-notification-button
        ref="navButton"
        class="box-10"
        @mouseleave="$refs.navButton.focus()"
        @mousedown="mousedownHandler"
        @focus="focusHandler"
        @blur="blurHandler"
        @keydown="keydownHandler"
      >
        <img src="@/assets/icons/bell.svg" alt="Notifications button" />
        <div
          v-if="unreadNotifications"
          class="absolute rounded-full flex justify-center items-center w-6 h-6 text-gray-0 bg-blue-300 -top-1.5 -right-1.5 text-10"
        >
          {{ unreadNotifications > 99 ? '99+' : unreadNotifications }}
        </div>
      </t-notification-button>
    </div>
    <div
      slot-scope="{ hide, blurHandler }"
      class="z-50 relative dropdown"
      @blur="blurHandler"
    >
      <div
        class="flex flex-wrap justify-between items-center text-gray-800 text-12"
      >
        <div class="mr-4">
          <p class="text-32 font-light">Notifications</p>
          <p>Recent</p>
        </div>
        <button class="uppercase font-extrabold" @click="markAllAsRead">
          mark all as read
        </button>
      </div>

      <div
        style="maxHeight: 369px"
        class="overflow-y-auto divide-y divide-blue-700 divide-opacity-30  border-t-2 border-b-2 border-blue-700 border-opacity-30 mt-4"
      >
        <AppNotificationItem
          v-for="notification in notifications"
          :key="notification.id"
          :notification="notification"
          :hide="hide"
          @mark-as-read="markAsRead"
        />
      </div>
      <div class="flex flex-col items-center text-gray-800 mt-8 mb-2">
        <img
          v-if="notifications.length"
          src="@/assets/icons/double_check.svg"
          alt="check"
          class="w-7"
        />
        <p class="text-12 w-44 font-medium text-center mt-2">
          {{
            notifications.length
              ? 'These are all your notifications from last 30 days'
              : "You don't have any notifications"
          }}
        </p>
      </div>
    </div>
  </notification-dropdown>
</template>
<script>
import { mapGetters } from 'vuex';
import * as api from '@/api';
import AppNotificationItem from '@/components/AppNotificationItem';

const NOTIFICATION_REFRESH_INTERVAL_SECONDS = 8;

export default {
  name: 'NotificationsButton',
  components: { AppNotificationItem },
  data() {
    return {
      timer: null,
      unreadNotifications: 0,
      notifications: [],
    };
  },
  computed: {
    ...mapGetters({
      user: 'user/getUser',
    }),
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  async mounted() {
    await this.fetchNotifications();
    this.timer = setInterval(async () => {
      await this.fetchNotifications();
    }, NOTIFICATION_REFRESH_INTERVAL_SECONDS * 1000);
  },
  methods: {
    async markAsRead() {
      await this.fetchNotifications();
      this.$refs.navButton.focus();
    },
    async fetchNotifications() {
      const { unread_count, results } = await api.get('notifications');
      this.unreadNotifications = unread_count;
      this.notifications = results;
    },
    async markAllAsRead() {
      await api.post('notifications/mark-all-as-read');
      await this.fetchNotifications();
      this.$refs.navButton.focus();
    },
  },
};
</script>
<style scoped>
.text-small {
  font-size: 10px;
}

.box-10 {
  width: 40px !important;
  height: 40px !important;
}

.dropdown {
  width: calc(100vw - 3rem) !important;
  max-width: 400px;
  min-height: 300px;
}
</style>
