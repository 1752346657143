<template>
  <img
    src="@/assets/icons/loading.svg"
    alt=""
    :class="`animate-spin ${classes}`"
  />
</template>
<script>
export default {
  name: 'Loader',

  props: {
    classes: {
      type: String,
      default: 'w-16 h-16 mb-10',
    },
  },
};
</script>
